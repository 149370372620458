import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
  </Layout>
)

export default IndexPage
