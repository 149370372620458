import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"
import burst from "../images/burst.png"

const ContainerStyle = css`
    width: 680px;
    height: 340px;
    background: #1F1F23;
    border-radius: 7px;
    position: relative;
    align-self: center;
    border: 1pt solid #1f1f23;
    margin-top: -20%;
    overflow: hidden;

    ::before {
        background-image: url(${burst});
        content: "";
        position: absolute;
        width: 400%;
        height: 400%;
        background-position: center;
        right: -121%;
        bottom: -140%;

        @keyframes spinny {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
        animation: spinny 60s linear infinite;
    }
    ::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        box-shadow: inset 0 0 0 1pt #f35542;
        border: 1pt solid #1f1f23;
        border-radius: 6px;
    }
`
const ImgStyle = css`
    height: 325px;
    width: auto;
    position: absolute !important;
    left: -1px;
    bottom: -1px;
    border-radius: 0 0 0 5px;
    z-index: 1;
`

const PacketStyle = css`
    position: absolute;
    top: 84px;
    right: 77px;
    display: inline-block;
    color: #f35542;
    text-shadow: 0 0 6pt #1B1B1E;
    z-index: 1;

    h1 {
        font: 38pt Staatliches;
        text-align: right;
        margin: 0;
        cursor: default;

        &::selection {
            color: inherit;
            background: transparent;
        }
    }
    .iconGroup {
        height: 24pt;
        border-top: 1pt solid #f35542;
        padding-top: 8pt;
        align-items: center;
        text-align: center;
        width: 80%;
        margin: 0 10%;

        a {
            color: inherit;
            text-decoration: none;
            font-size: 16pt;
            margin: 0 3pt;
            position: relative;
            display: inline-block;
            vertical-align: top;

            &:hover {
                color: #FF6E5E;
            }
        }
        div {
            width: 85%;
            margin: 3pt auto;
            text-align: left;
        }
        div > i {
            position: relative;
            top: 4px;
            margin-right: 4pt;
            font-size: 16pt;
        }
        div:first-of-type > i {
            top: 3px;
        }
        div .fa-discord {
            margin-left: 1pt;
            margin-right: 5pt;
        }
        div span {
            font: 500 10pt "Poppins";
            display: inline-block;
            vertical-align: baseline;
        }
        div:last-of-type {
            margin-top: 3pt;
        }
        div:last-of-type a {
            position: relative;
            top: 2.5pt;
        }
        div:last-of-type span {
            font-size: 16pt;
            margin-left: 3.5pt;
            margin-right: 3.5pt;
        }
    }
`

const Hero = () => {
    const data = useStaticQuery(graphql`
            query {
                placeholderImage: file(relativePath: { eq: "hero.png" }) {
                    childImageSharp {
                        fixed(height: 325) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `)

    return <>
        <Helmet>
            <link href="https://fonts.googleapis.com/css?family=Poppins:500|Staatliches&display=swap" rel="stylesheet" />
            <script src="https://kit.fontawesome.com/e6713c6c74.js"></script>
        </Helmet>
        <div css={ContainerStyle}>
            <Img css={ImgStyle} fixed={data.placeholderImage.childImageSharp.fixed}></Img>
            <div css={PacketStyle}>
                <h1>the clockler</h1>
                <div className="iconGroup">
                    <div>
                        <i class="fas fa-at"></i>
                        <span>hello@clockler.com</span>
                    </div>
                    <div>
                        <i class="fab fa-discord"></i>
                        <span>@clockler#0291</span>
                    </div>
                    <div>
                        <span>+</span>
                        <a href="https://github.com/clockler"><i class="fab fa-github"></i></a>
                        <a href="https://twitter.com/clockler"><i class="fab fa-twitter"></i></a>
                        <a href="https://keybase.io/clockler"><i class="fab fa-keybase"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Hero